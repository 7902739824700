@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');


/* @media only screen and (max-width: 1010px) and (min-width: 535px)  {
  .dashboard-row-col-sm-4  {
    width:100% !important;
  }
} */


body, html {
  margin: 0;
  font-family: 'Fredoka', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#000 !important;
  height:100% !important;
  padding:0;
}

.landing-main {
  padding:1rem;
  background: rgba(0,0,0,0.25);
  background:url('./images/landing-main.jpg') 0% 50% no-repeat;
  text-shadow:1px 1px 1px #000;
  height:100%;
  min-height:100vh;

  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
.landing-main-title {
  padding:1rem;
  margin:1rem;
  text-align:center;
  color:#FFF;
  margin-top:3rem;
}
.custom-#A51318main {
  background:#C3171D;
  color:#FFF;
  padding:0em 1rem;
}
.landing-page-button {
  text-align:center;
}
.landing-page-button .btn-dark {
  background:#C3171D;
  border:none;
  padding:1rem 2rem;
  font-weight:300;
  font-size:2em;
  box-shadow: 1px 3px 3px rgba(0,0,0,0.75) !important;
}
.landing-page-button .btn-dark:hover, .landing-page-button .btn-dark:focus {
  background:#d61f25;
}
.user-block {
  margin:0.75rem 0.25rem;
  padding:1rem;
  background:rgb(26, 26, 26, 0.5);
  color:#FFF;
  padding-bottom:0px;
  border-radius:10px;
  font-weight:300;
  text-shadow:none;
  color:#d61f25; 
  border: solid 1px rgba(178, 22, 27, 0.15);
}
.user-block .btn-dark {
  background:#d61f25;
  border-color:#d61f25;
}
.user-block .btn-dark:hover, .user-block .btn-dark:focus {
  background:#A51318;
  border-color:#A51318;
}
.user-block hr {
  background-color:rgba(255,255,255,0.25)
}
.online {
  color:green;
  font-weight:500;
}
.offline {
  color:red;
  font-weight:500;
}
.margin-row {
  margin-top:5rem !important;
}
.verified {
  position:absolute;
  top:10px;
  right:20px;
  color:#d61f25;
  font-size:2em;
}
.custom-modalcontent .modal-content {
  background:transparent !important;
  color:#FFF;
}
.custom-modalcontent .col-sm-12 {
    -webkit-box-shadow: 1px 1px 30px 15px rgba(178, 22, 27, 0.25); 
  box-shadow: 1px 1px 30px 15px rgba(255, 0, 0, 0.25); 
  border:solid 10px rgba(0,0,0,0.85);
}
.custom-modalcontent .badge {
  background:#d61f25 !important;
}
.form-step {
  margin:1rem;
  padding:1rem;
  color:#d61f25;
  font-weight:900;
  text-shadow: 1px 1px 1px #000;
}
.form-btnleft {
  position:absolute;
  bottom:10%;
  left:5%;
}
.form-btnright {
  position:absolute;
  bottom:20px;
  right:5%;
}
.custom-modalcontent .btn-dark {
  background:#d61f25;
  border-color:#d61f25;
  box-shadow:1px 1px 5px 5px rgba(0,0,0,0.5);
}
.custom-modalcontent .btn-dark:hover, .custom-modalcontent .btn-dark:focus {
  background:#A51318;
  border-color:#A51318;
  box-shadow:1px 1px 5px 5px rgba(0,0,0,0.5);
}
.custom-modalcontent .modal-body {
  padding:0rem !important;
}
.custom-modalcontent .text-muted {
  color:#FFF !important;
  font-weight:500;
  opacity:0.75;
}
.form-step .btn-dark {
  background:black;
}
.ctm-error {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color:red;
  background:rgba(0,0,0,0.85);
  padding:0.25rem 1rem; 
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  font-size:14px;
  bottom:10px;
  text-align: center;
}
.custom-modalcontent .form-control {
  background:#000;
  border-color:#A51318;
  color:#FFF;
  box-shadow:none !important;
}
.custom-modalcontent .form-control:focus {
  background:#000;
  border-color:#A51318;
  color:#FFF;
}
.lds-dual-ring {
  display: inline-block;
  width: 128px;
  height: 128px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 92px;
  height: 92px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #A51318;
  border-color: #A51318 transparent #A51318 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.75);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index:20 !important;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ringsmall {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.finished-form h1 {
  text-align: center;
  font-size:5em;
  margin-top:2rem;
}
.finished-form p {
  text-align: center;
  font-size:2em;
  font-weight:500;
}
.from-step-welcome {
  color:#FFF;
  font-weight:500;
  text-align:center;
}

.dashboard-main {
  padding:1rem;
  background: rgba(0,0,0,0.25);
  background:url('./images/dashboard-main.jpg') 0% 50% no-repeat;
  text-shadow:1px 1px 1px #000;
  height:100%;
  min-height:100%;

  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  margin-bottom:2rem;
}
.dashboard-main-backdrop {
  position:fixed;
  width:100%;
  height:100%;
  top: 0;
  left: 0;
  z-index:1;
  background:rgba(0,0,0,0.75);
}
.dashboard img {
  z-index:10;
  position:absolute;
  left:0;
  right:0;
  margin:auto;
}
.dashboard-row {
  width:100%;
  height:auto;
  top:2em;
  border-radius:10px;
  color:#FFF;
}
.dashboard-row {
  -webkit-box-shadow: 1px 1px 30px 15px rgba(178, 22, 27, 0.25); 
  box-shadow: 1px 1px 30px 15px rgba(255, 0, 0, 0.25); 
  border:solid 10px rgba(0,0,0,0.85);
  background:rgba(0,0,0,0.75);
}
.dashboard-row-col-sm-4 {
  padding:0px !important;
  background:#A51318;
  border-radius:5px;
}
.dashboard-row-col-sm-8 {
  padding:0px !important;
}
.dashboard-welcome {
  background:rgba(0,0,0,0.5);
}
.dashboard-welcome p {
  padding:1rem;
  font-weight:300;
  border-top:solid 1px rgba(0,0,0,0.15);
  opacity:0.75;
}
.leftside ul {
  list-style:none;
  margin:1rem;
  margin-left:-20px;
  width:100%;
}
.leftside ul a {
  text-decoration: none;
  color:#FFF;
}
.leftside ul li {
  background:rgba(0,0,0,0.15);
  padding:0.75rem 1.5rem;
  margin:1rem;
  border-radius:5px;
  text-shadow: none;
  cursor:pointer;
}
.leftside ul li:hover {
  transition: 1s;
  background:rgba(0,0,0,0.5);
}
.leftside ul .active li {
  background:rgba(0,0,0,0.5) !important;
  color:#d61f25;
  transition:1s;
}
.leftside ul .active li .custom-arrow {
  margin-right:0rem;
}
.leftside ul li:hover .custom-arrow {
  margin-right:0rem;
  transition: 1s;
}
.custom-arrow {
  float:right;
  display:inline;
  margin-right:1rem;
}
.db-title {
  color:#d61f25;
}
.dashboard-right {
  padding:0.5rem;
  min-height:300px;
}
.dashboard-right hr {
  background-color:rgba(255, 0, 0, 0.5);
}
.dashboard-right h3 {
  font-weight:300;
}
.dashboard-right .btn-dark {
  background:#d61f25;
  border-color:#d61f25;
  box-shadow:1px 1px 5px 5px rgba(0,0,0,0.5);
}
.dashboard-right .btn-dark:hover, .custom-modalcontent .btn-dark:focus {
  background:#A51318;
  border-color:#A51318;
  box-shadow:1px 1px 5px 5px rgba(0,0,0,0.5);
}
.db-sign {
  float:right;
}
.profile-infoblock {
  padding:1rem;
  background:rgb(26, 26, 26, 0.5);
  margin:0.5rem;
  text-shadow: none;
  cursor:pointer;
}
.profile-infoblock label {
  color:#A51318;
  font-weight:500;
  font-size:1.2em;
}
.profileavatar {
  margin:3rem;
}
.profileavatar .img-thumbnail {
  background-color: #d61f25;
  border-color:#d61f25;
}
.edit-photo-btn {
  position:absolute;
  top:0;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
}
.dash-modal {
  padding:1rem;
  margin:1rem;
}
.dash-modal h1 {
  text-align:center;
  font-weight:300;
  color:#A51318;
}
.db-startmessage {
  padding:1rem;
  margin:1rem;
}
.db-startmessage section {
  background: linear-gradient(rgba(211, 0, 0, 0.5), transparent);
  padding:1rem;
  box-shadow: inset 1px 1px 7px 2px #000;
}
.db-startmessage section h2 {
  color:#A51318;
}
.db-startmessagestep {
  margin:1rem;
  padding:1rem;
  background:rgba(0,0,0,0.25);
  border-radius:10px;
  box-shadow: inset 1px 1px 7px 2px #000;
  animation:pulse 0.5s infinite alternate;
}
.db-startmessagestep h2 {
  padding:1rem 0rem;
}
.db-startmessagestep .number {
  width:70px;
  border:solid 5px #A51318;
  border-radius:50%;
  padding:0.5rem 1rem;
  font-weight:700;
  font-size:1.5em;
}

@keyframes pulse {
  from { box-shadow:0px 0px 10px 1px #A51318; }
  to { box-shadow:0px 0px 20px 3px #A51318; }
}

.hon-game {
  background: linear-gradient(rgba(211, 0, 0, 0.5), transparent);
  padding:1rem;
  border-radius:5px;
  margin:0rem 0.5rem;
}
.hon-game h1 {
  text-align:center;
  font-weight:900;
  color:#A51318;
}
.hon-game p {
  text-align: center;
  opacity:0.5;
}
.hon-game h3 {
  text-align:center;
  font-weight:300;
}
.hon-game hr {
  background:rgba(0,0,0,0.75);
}
.hon-startgame {
  margin-top:1rem;
  background:transparent;
  border:none;
  background:rgba(255, 0, 0, 0.5);
  color:#FFF;
  font-size:3em;
  padding:1rem 2rem;
  padding-left:2.5rem;
  font-weight:500;
  opacity:0.5;
  animation:pulse 0.5s infinite alternate;
  border-radius:50%;
}
.hon-startgame:hover, .hon-startgame:focus {
  transition:1s;
  opacity:1;
}
.hon-card {
  background: linear-gradient(rgba(211, 0, 0, 0.5), transparent);
  padding:1rem;
  border-radius:10px;
}
.hon-card .img-thumbnail {
  border-color:rgba(0,0,0,0.25);
  background-color:rgba(0,0,0,0.5)
}
.hon-loader {
  text-align:center;
  margin-top:1rem;
}
.hon-cardsbtns {
  position:absolute;
  top:0;
  left:0;
  right:0;
  margin-left:auto;
  background:rgb(90, 0, 0);
  margin-right:auto;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
  z-index:1;
  -webkit-box-shadow: 0px 4px 2px -1px #720000;
  box-shadow: 0px 4px 2px -1px #720000;
}
.hon-cardsbtns .btn {
  border-radius:50%;
  opacity:0.5;
  border:none;
  text-shadow: 0px 1px 0px #000;
}
.hon-cardsbtns .btn:hover {
  opacity:1;
  transition:1s;
  animation:pulse 0.5s infinite alternate;
}
.profile-infoblockcontent p {
  color:#A51318;
  margin:0px;
}
.profile-infoblockcontent span {
  font-size:12px;
  font-weight:300;
}

.addbordercontent {
  height: 825px;
  overflow-y: scroll;  
}

.addbordercontent-likes {
  height: 500px;
  overflow-y: scroll;  
}

  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #A51318 #000000;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #000000;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #A51318;
    border-radius: 10px;
    border: 3px solid #A51318;
  }
  .custombadge-m {
    background:#A51318;
    padding:0.25rem 0.75rem;
    font-size:12px;
    border-radius:10px;
    color:#FFF;
    animation:pulse 0.5s infinite alternate;
    margin-right:1rem;
    float:right;
    display:inline;
    border:solid 1px rgba(0,0,0,0.25);
  }

.mapavatar {
  border-radius:50%;
  object-fit: cover;
  border:solid 5px #A51318;
  animation:pulse 0.5s infinite alternate;
}
.google-content {
  height: auto;
  width: 100%;
  text-shadow: none;
}
.profile section {
  background: linear-gradient(rgba(211, 0, 0, 0.5), transparent);
  padding:1rem;
  box-shadow: inset 1px 1px 7px 2px #000;
}
.profile-p .img-thumbnail {
  border-color:rgba(255, 0, 0, 0.5);
  background-color:rgba(255, 0, 0, 0.5)
} 
.profile ul {
  width:100%;
}
.profile ul .nav-item {
  width:50% !important;
  text-align:center;
  color:rgba(255, 0, 0, 0.75);
}
.profile ul .nav-item button {
  width:100%;
  color:rgba(255, 0, 0, 0.75);
  font-size:1.5em;
  font-weight:300;
}
.profile ul .nav-item .nav-link:hover {
  border-color:rgba(255, 0, 0, 0.5)
}
.profile ul .nav-item .nav-link.active {
  background:rgba(255, 0, 0, 0.5);
  border-color:rgb(255, 0, 0, 0.5);
  color:#FFF;
  font-weight:500;
}
.profile .nav-tabs {
  border-bottom:solid 1px rgb(255, 0, 0, 0.5);
}
.profile .nav-link {
  border-radius:0px !important;
}
.leftside .navbar-toggler {
  margin:auto;
}
.banners-track {
  padding: 1rem;
  background: rgb(26, 26, 26, 0.5);
  color:#FFF;
  text-align: center;
  border-top:solid 1px rgba(0,0,0,0.5)
}
.dashboard-footer {
  text-align: center;
  font-weight:300;
}
.dashboard-footer hr {
  background-color: rgba(0,0,0,0.5);
}
.ads-row {
  background:#fff;
  margin-right:1rem;
  margin-top:1rem;
  margin-bottom:1rem;
  padding:1rem;
}

.dashboard-footer {
  color:#FFF;
  margin-top:1rem;
}

@media (max-width: 1400px) {
  .custombadge-m {
    float:none !important;
    display:inline-block !important;
  }
}

@media (max-width: 768px) { 
  .iframe_embed_css {
    z-index:99999 !important;
  }
  .hidemobile-rightsidebar {
    display:none !important;
  }
  .hide-nativeadsmobile {
    display: none !important;
  }
  .display-mobilebb {
    display: block;
    position:fixed;
    bottom:0;
    width:100%;
    height:80px;
    background: linear-gradient(225deg, #4f3030 0%, #a50101 100%);
    z-index:99;
    border-top:solid 1px rgba(255, 255, 255, 0.25)
  }
  .display-mobilebb-item {
    display:inline-block;
    width:30%;
    font-size:40px;
    padding:0.25rem;
    margin:0.25rem;
    text-align: center;
    color:#fff;
    transition:1s;
  }
  .display-mobilebb-item:hover {
    background:rgba(0,0,0,0.25);
    color:#A51318;
  }
  .hon-card-image-btns {
    bottom: 50px !important;
  }
  .hon-card-image-btns button {
    margin:0.25rem;
  }
  .user-block .col-sm-12 button {
    margin:0.25rem;
    width:100%;
  }
  .comment-row-profile small {
    position:relative !important;
  }
  .member-model-img {
    min-height:300px !important;
  }
  .dashboard-footer {
    margin-bottom:5rem !important;
  }
 }


@media (min-width: 768px) {
  .display-mobilebb {
    display: none !important;
  }
}
.additional-page {
  padding:1rem;
  margin:1rem;
  color:gray;
}
.additional-page h2 {
  color:#A51318;
}
.additional-page h1 {
  color:#A51318;
  font-weight:700;
  text-align: center;
}
.addbordercontent a {
  color:#FFF;
  text-decoration: none;
}
.addbordercontent a:hover {
  color:#fff;
}
.elementor-kit-2483 {
  background:#000 !important;
  color:#FFF !important;
}
.modal-fullscreen .modal-header {
  background:#000;
  color:#A51318;
  border-color:#A51318;
}
.modal-fullscreen .btn-close {
  background-color:#A51318 !important;
}
.modal-fullscreen .modal-body {
  background:#000;
  color:#FFF;
}
.custom-headermodal {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  color:#f60202 !important;
}
.infopage-body {
  margin:1rem;
  padding:1rem;
  background:url('./images/landing-main.jpg') 0% 50% no-repeat;
}
.customcontent-addpages {
  background:rgba(0,0,0,0.9);
  padding:1rem;
  box-shadow:1px 4px 4px 4px #000;
}
.customfooter {
  padding:0.5rem;
  border-top:solid 3px #000;
  background:rgb(8, 8, 8);
}
.modaltopbar {
  position:absolute;
  bottom:60px;
  right:25px;
  text-align: center;
}
.smalllogomodaltop {
  margin-top:2rem;
}
.modaltopbar a {
  display:block;
  font-size:12px;
  color:rgb(128, 0, 0);
  text-decoration: none;
}
.modaltopbar a:hover {
  color:rgb(198, 0, 0)
}
.adspanel-login {
  color:gray;
  padding:1rem;
  background:#111;
  width:50%;
  margin:auto;
  margin-top:3rem;
  border:solid 1px rgba(255, 0, 0, 0.25);
}
.adspanel-login .form-control {
  background:#000;
  border-color:#A51318;
  color:#FFF !important;
}
.adspanel-login .form-control:hover, .adspanel-login .form-control:focus {
  background:#000;
  border-color:#c50000;
  box-shadow:none !important;
}
.adspanel-login h3 {
  text-transform: uppercase;
  color:#c50000; 
}
.adspanel-login hr {
  background-color:#333;
}
.form-step a {
  color:rgb(128, 0, 0);
  font-weight:bold;
  text-decoration: none;
}
.form-step a:hover {
  color:rgb(255, 0, 0)
}
.adspanel-private {
  padding:1rem;
  margin:1rem;
  color:rgb(128, 0, 0);
}
.custom_adsmodal .modal-content {
  background:#111 !important;
  color:silver;
  border:solid 1px rgb(128, 0, 0);
}
.custom_adsmodal .modal-header {
  border-bottom: solid 1px rgb(71, 0, 0) !important;
  color:rgb(128, 0, 0);
  font-weight:900;
  text-transform: uppercase;
}
.custom_adsmodal .modal-footer {
  border-top: solid 1px rgb(71, 0, 0) !important;
}
.custom_adsmodal .btn-close {
  background-color:rgb(128, 0, 0) !important;
}
.custom_adsmodal input, .custom_adsmodal select {
  background:#000;
  border-color:rgb(71, 0, 0);
  color:#FFF !important;
}
.custom_adsmodal input:hover, .custom_adsmodal input:focus {
  border-color:rgb(128, 0, 0);
  background:#000 !important;
  box-shadow: none !important;
}
.custom_adsmodal select:hover, .custom_adsmodal select:focus {
  border-color:rgb(128, 0, 0);
  background:#000 !important;
  box-shadow: none !important;
}
.ads-block {
  margin:1rem 0rem;
  padding:1rem;
  background:#111;
  color:silver;
}

.ads-block a {
  color:rgb(128, 0, 0);
  text-decoration: none; 
}
.ads-block a:hover {
  color:rgb(255, 0, 0)
}
.custom-iframemodal .modal-content {
  background:rgba(0,0,0,0.9);
  /* padding:1rem; */
  color:rgb(128, 0, 0);
  border:solid 1px rgba(255, 0, 0, 0.25);
  height:auto;
  height:800px;
}
.custom-iframemodal .btn-close {
  background-color:rgb(255, 0, 0, 0.25);
}
.custom-iframemodal .modal-header {
  border-color: rgba(255, 0, 0, 0.25);
}
.cusxm .modal-content {
  background-color: transparent !important;
  border: none;
}

.db-startmessage a {
  text-decoration: none;
  color:#fff;
}
.db-startmessage a:hover {
  color:#fff;
}

.new-iframebody {
  background:url('./images/iframe-bg.jpg') 0% 50% no-repeat !important; 
  height:100%;
  min-height:100vh;

  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
.new-iframebody h2 {
  color:#A51318;
  font-weight:500;
  text-shadow: 1px 1px 1px #000;
}
.new-iframebody h3 {
  color:silver;
  font-weight:500;
  text-shadow: 1px 1px 1px #000;
}
.new-iframebody-rightside {
  background:linear-gradient(to right, rgba(0,0,0,0.5), transparent);
  padding:1rem;
}
.new-iframebody-rightside-title {
  color:#A51318;
  font-weight:500;
  text-transform: uppercase;
  font-size:30px;
}
.new-iframebody-rightside ul li {
  margin:0.25rem;
}
.new-iframebody-rightside hr {
  background:#A51318
}
.new-iframebody-logo {
  background:rgba(0,0,0,0.75);
  padding:1rem;
}
.new-iframebody-rightside-block {
  text-align: center;
  background:rgba(208, 0, 0, 0.5);
  color:#FFF !important;
  padding:1rem;
}
.new-iframebody-rightside-block h2 {
  color:#FFF !important;
}
.verify_text {
  color:#FFF;
  text-decoration: none;
  font-size:13px;
}
.verify_text:hover {
  color:#FFF !important;
  opacity:0.5;
}
.ca-popover {
  z-index:9999;
  position:absolute;
  width:98%;
  height:99%;
  background:rgba(0,0,0,0.8);
  top:0;
}
.ca-popover img {
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Toastify__toast-theme--light {
  background: #000 !important;
  color:#FFF !important;
  border:solid 1px #2e0101;
}
.Toastify__close-button--light {
  color:#FFF !important;
}
.Toastify__progress-bar {
  background: #A51318 !important;
}
.nav-tabs .nav-link {
  color:#A51318 !important;
}
.nav-tabs .nav-link.active {
  background:#A51318 !important;
  color:#FFF !important;
  border-color:#A51318 !important
}
.nav-tabs {
  border-bottom:solid 1px #A51318 !important;
}
.nav-tabs .nav-link:hover {
  border-color:#A51318 !important;
}
.space10px {
  height:10px;
}
.form-check-input:checked {
  background-color: #A51318 !important;
  border-color: #A51318 !important;
}
.hon-card-image-btns a {
  text-decoration: none;
}
.hon-card-image-btns {
  position: absolute;
  bottom: 0px !important;
  left: 50%;

  transform: translate(-50%, -50%);
}
.hon-card a {
  text-decoration: none;
}
.blocked-content {
  width:100%;
  background:rgb(30, 30, 30);
  height:100%;
  padding:1rem;
  padding-top:1.5rem;
  margin:0.25rem 0rem;
  text-align: center;
  filter: blur(2px);
}
.user-block-img {
  width:100%;
  height:175px;
}
.btn-gp {
  background: radial-gradient(circle at center, #4f3030 , #f85555);
  border-color: #f85555 !important;
  text-shadow:1px 1px 1px rgba(0,0,0,0.25);
  animation: glow 1s infinite alternate;

}
.btn-fn {
  background: radial-gradient(circle at top, #ba2d2d , #fb8686);
  border-color: #fb8686 !important;
  text-shadow:1px 1px 1px rgba(0,0,0,0.25);
  animation: glow 1s infinite alternate;

}
.btn-lc {
  background: radial-gradient(circle at right, #ba2d2d , #fb8686);
  border-color: #fb8686 !important;
  text-shadow:1px 1px 1px rgba(0,0,0,0.25);
  animation: glow 1s infinite alternate;

}

@keyframes glow {
  from {
    box-shadow: 0 0 3px -3px rgba(255,255,255,0.25);
  }
  to {
    box-shadow: 0 0 3px 3px rgba(255,255,255,0.25);
  }
}

@keyframes glow-pink {
  from {
    box-shadow: 0 0 3px -3px rgba(255, 0, 0, 0.5);
  }
  to {
    box-shadow: 0 0 3px 3px rgba(137, 0, 0, 0.5);
  }
}

.ast
 {
  color:#FFF !important;
  text-decoration: none !important;
}
.visitor-block {
  transition: 1s;
  background:rgba(161, 0, 0, 0.25);
  padding:1rem;
  margin:0.5rem 0rem !important;
}
.visitor-block span {
  background:rgba(0,0,0,0.5);
  padding:0.25rem 0.5rem;
  color:green;
  font-size:13px;
  border-radius:5px;
}
.visitor-block h4 {
  font-size:20px;
  padding-top:0.25rem;
}
.visitor-block-image {
  height:70px;
  width:100%;
}
.visitor-block-image img {
  width:100%;
  height:100%;
  object-fit: cover;
}
.general-visitor-block a {
  text-decoration: none !important;
  color:#FFF;
}
.visitor-block:hover {
  color:#fff;
}
.visitor-block:hover h4 {
  color:#A51318;
  transition:1s;
}
.comment-row-profile {
  background:rgba(161, 0, 0, 0.25);
  padding:1rem;
  padding-bottom:0.5rem;
  margin:0.5rem 0rem;
}
.comment-row-profile small {
  color:gray;
  position:absolute;
  top:0;
  right:0;
}
.comment-row-profile-image {
  width:100%;
  height:50px;
}
.comment-row-profile-image img {
  width:100%;
  height:100%;
  object-fit: cover;
}
.general-block-comments a:hover {
  color:#FFF;
}
.general-block-comments:hover {
  color:#fff;
  text-decoration: none;
}
.general-block-comments:hover b {
  color:#A51318;
  transition:1s;
}
.general-visitor-block .img-thumbnail {
  border-color:#d61f25;
  background-color:#d61f25;
  border-width:1px;
}
.post-image {
  width:100%;
  height:250px;
}
.post-image-v2 {
  width:100%;
  max-height:400px;
}
.post-body {
  padding:1rem;
  margin:1rem;
  background:rgb(51, 51, 51, 0.25);
  color:#FFF;
  border:solid 1px rgb(255, 0, 0);
}
.comments-profile-row {
  width:100%;
  background-color: rgba(255, 0, 0, 0.05);
  color:#FFF;
  margin:0.25rem 0rem !important;
  border:solid 1px rgba(255, 0, 0, 0.25);
  padding:0.25rem
}
.comments-profile-row a {
  color:#cb0000;
  text-decoration: none;
}
.comments-profile-row a:hover {
  color:#cb0000;
}
.comments-profile-row span {
  font-size:11px;
}
.profile-c-img {
  height:50px;
  width:100%;
  margin-top:0.5rem
}
.profile-c-img img {
  width:100%;
  height:100%;
  object-fit: cover;
}
.addbordercontent-comments {
  max-height: 300px;
  overflow-y: scroll;  
}
.css-1s2u09g-control {
  border-color: #A51318 !important;
    background: #000 !important;
    box-shadow: none !important;
}
.css-cj7im2-control {
  border-color: #A51318 !important;
  background: #000 !important;
  box-shadow: none !important;
}
#react-select-4-listbox {
  background:rgb(28, 28, 28) !important;
  color:#FFF !important;
}
.css-1rhbuit-multiValue {
  background:rgb(28, 28, 28) !important;
}
.css-12jo7m5 {
  color:#FFF !important;
}
.select__control:hover {
  background: black !important;
  color:#FFF !important; 
}
.addbordercontent-x {
  max-width: 100%;
  overflow-x: scroll;  
}
.search-bytag button {
  margin:0.25rem
}
.search-bytag p {
  font-size:18px;
  color:gray;
  font-weight:300;
  margin:0.25rem
}
.members-buttons-footer {
  margin:1rem 0rem;
}
.blured-row {
  filter: blur(4px);
}
.blured-message {
  position: absolute;
  z-index:10;
  filter: blur(0px);
  top:0;
  left:0;
  background:#A51318;
  width:100%;
  color:#FFF;
  padding:1rem;
  margin:0.25rem;
  margin-bottom:0rem;
  padding-bottom:0rem;
  padding-top:0.25rem;
  text-align: center;
  margin-top:5px;
  width:99%;
}
.blured-message .btn {
  animation: pulse 0.5s infinite alternate;
}
.last-bottom-custom {
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  margin-left:12px;
  margin-right:12px;
  border-radius:0px !important;
}
.modals-like .modal-content {
  background:#111;
  color:#FFF;
  border:solid 1px rgba(255, 0, 0, 0.75);
}
.modals-like .modal-header {
  border-bottom: solid 1px rgb(255, 0, 0, 0.75);
  color:#A51318;
}
.modals-like .modal-footer {
  border-top: solid 1px rgb(255, 0, 0, 0.75);
}
.user-like-item {
  padding:0.25rem;
  margin:0.25rem;
}
.user-like-item a {
  color:#A51318;
  text-decoration: none;
}
.user-like-item a:hover {
  color:#d40000;
}
.reply-form .form-control {
    background:#111;
    border-color:#A51318;
    color:#FFF !important;
}
.reply-form .form-control:hover, .reply-form .form-control:focus {
    background:#111;
    border-color:#c50000;
    box-shadow:none !important;
}
.liveblock-cam {
  margin:0.25rem;
  padding:0.25rem;
  border:solid 2px #A51318;
  margin-bottom:1rem;
}

.wrapScroll {
  white-space: nowrap;
  overflow-x: auto;
  border: 1px solid #A51318;
}
.video-badge {
  position:absolute;
  top:15px;
  left:15px;
  background:rgba(0,0,0,0.75);
  z-index:99;
  font-size:13px;
  padding:0.25rem 1rem;
  border-radius:4px;
  animation:pulse 0.5s infinite alternate;
}
.video-badge i {
  color:#A51318;
}

a.notfa {
  color: #FFF !important;
  text-decoration: none !important;
}
.custom_adsmodal_split .modal-content {
  background:#111 !important;
  color:silver;
  border:solid 1px #A51318;
}
.custom_adsmodal_split .modal-header {
  border-bottom: solid 1px rgb(255, 0, 0) !important;
  color:#A51318;
  font-weight:900;
  text-transform: uppercase;
}
.custom_adsmodal_split .modal-footer {
  border-top: solid 1px rgb(255, 0, 0) !important;
}
.custom_adsmodal_split .btn-close {
  background-color:#A51318 !important;
}
.split_input {
  background:#000 !important;
  border-color:rgb(255, 0, 0) !important;
  color:#FFF !important;
}
.split_input:focus {
  border-color:#A51318  !important;
  background:#000 !important;
  box-shadow: none !important;
}
.info-form-imp {
  color:#FFF !important;
  text-decoration: none !important;
  font-weight:300 !important;
  margin-top:0.5rem;
}
.custom-modal-notf {
  position:absolute;
}
.Toastify__toast-container--top-center{
  margin-left: - (your width / 2) !important
}
.customtoas_width {
  margin-top:3rem;
  min-width:500px;
}
.customtoas_width .Toastify__toast-icon {
  border-radius:50% !important;
  width:40px !important;
}
.customtoas_width .Toastify__toast-theme--light {
  background:#111 !important;
  box-shadow: 1px 1px 30px 15px rgba(255, 0, 0, 0.205) !important;
  border: solid 10px rgba(0,0,0,0.85) !important;
}
.customtoas_width .Toastify__toast--error {
  color:yellow !important;
}

.form-step input:focus {
  box-shadow: 1px 1px 8px 3px rgba(255, 58, 58, 0.5) !important;
}

.model-main-form {
  padding:1rem;
  margin:1rem;
}
.model-main-form h1 {
  font-weight:300;
  color:silver;
}
.model-main-count {
  text-align: center;
  color:#FFF;
}
.model-main-count span {
  display:inline-block;
  background: #ff0000; 
  background: -webkit-linear-gradient(to right, #ff0000, #7a0000);
  background: linear-gradient(to right, #ff0000, #7a0000);
  text-shadow: 1px 1px 1px #000;
  font-size:2em;
  padding:1rem 2rem;
  margin:0.25rem;
  animation: glow-pink-new 1s infinite alternate;
}
.model-main-step {
  padding:1rem 0rem;
  color:#FFF;
}
.model-main-step h2 {
  font-weight:300;
}

.button-prototype {
  background:#d61f25 !important;
  border-color:#d61f25 !important;
  box-shadow:1px 1px 5px 5px rgba(0,0,0,0.5) !important;
}
.button-prototype:hover, .button-prototype:focus {
  background:#A51318 !important;
  border-color:#A51318 !important;
  box-shadow:1px 1px 5px 5px rgba(0,0,0,0.5) !important;
}
.model-main-form hr {
  background: #A51318; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #A51318, #810A0E); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #A51318, #810A0E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.model-main-form input {
  background:#000;
  border-color:rgb(128, 17, 17);
  color:#FFF !important;
  padding:1rem;
}
.model-main-form input:hover, .model-main-form input:focus {
  border-color:#A51318;
  background:#000 !important;
  box-shadow: none !important;
}
.model-main-form a {
  color:rgba(128, 17, 78);
  text-decoration: none;
}
.model-main-form a:hover {
  color:#A51318;
}
.model-main-form .info-form-imp {
  padding:1rem 0rem;
  font-size:16px;
}
.member-model-img {
  min-height:100vh;
}
.member-model-inf {
  position:absolute;
  bottom:0;
  right:0;
  background:rgba(255, 0, 0, 0.85);
  min-width:300px;
  padding:1rem;
  color:#FFF;
}
.member-model-inf p {
  margin-bottom:0.25rem !important;
}

.custom-form-control {
  background:#000 !important;
  border-color:#A51318 !important;
  color:#FFF !important;
  box-shadow:none !important;
  max-width:140px !important;
}
.custom-form-control::-webkit-calendar-picker-indicator {
  filter: invert(1) !important;
}
.datepicker-wrapper .datepicker {
  background-color: #6c757d;
  border-color: #6c757d;
}

.datepicker-wrapper .datepicker-dropdown {
  background-color: #6c757d;
  border-color: #6c757d;
}

.datepicker-wrapper .datepicker .selected-day {
  background-color: #d30000;
}

.datepicker-wrapper .datepicker .selected-day:hover {
  background-color: #b02727;
}
.custom-table-span {
  color:#FFF;
}
.stats-table-custom {
  height:100%;
}
.stats-table-custom thead {
  background: #A51318 !important;
    color: #FFF !important;
}
.stats-table-custom tr {
  border-color:#A51318 !important;
}
.stats-table-custom .form-label {
  color:#A51318;
}
.row-t-info {
  color:#FFF;
  font-size:11px
}
.row-t-info span {
  color:silver;
}
.row-t-info label {
  color:#FFF;
  text-transform: uppercase;
}
.last-line {
  background:#A51318;
  color:#FFF;
}
.stats-table-custom .badge {
  font-weight:300 !important;
}
.no-rad {
  border-radius:0px !important;
}

.ReactTable .rt-thead.-header {
  background:#A51318 !important;
  color:#FFF;
}
.ReactTable .rt-tbody .rt-td {
  color:#FFF !important;
}
.rt-th input {
  background:#000 !important;
  border:solid 1px #A51318 !important;
  color:#FFF !important;
}
.centered-bg {
  background-position: center !important;
}
.terms-footer-custom {
  color:#FFF;
  font-size:12px;
}
.terms-footer-custom a {
  color:#A51318;
  text-decoration: none !important;
}
.terms-footer-custom a:hover {
  color:#A51318;

}